import App from '../App';
import { useFetcher } from 'react-router-dom';
import './styles/Pages.css'
import { useState, useEffect } from 'react';
import Footer from '../Components/Footer';
import Header from './Header'
import { HashLink } from 'react-router-hash-link';
import Ligneimg from './img/Circle/lignefibre.png';
import Voip from './img/Circle/Voip.png';
import Casque from './img/Circle/Casque.png';
import lignefibre from './img/Circle/lignefibre.png';
import Musiquedatt from './img/Circle/Musiquedatt.png';
import Portable from './img/Circle/portable.png';
import Support from './img/Circle/SupportEcoute.png';
import Fibre from './img/Circle/Fibre.png';
import vdsl from './img/Circle/vdsl.png';
import adsl from './img/Circle/adsl.png';
import Mobile from './img/Circle/Mobile.png';

import './styles/Service.css'
function Service() {
    return (<>
        <Header />
        <div className='widget ServiceCtnr'>
            <h1 className='Title'>Nos Services</h1>
            <h1 className='Titleh2'>Réseaux :</h1>
            <br />
            <div className='joiner'>
                <a href='Fibre_Adsl'>
                    <img src={adsl} className="ligne img"></img>
                </a>
                <HashLink to="/Fibre_Adsl#vdsl">
                    <img src={vdsl} className="ligne img"></img>
                </HashLink>
                <a href='Fibre_Adsl'>
                    <img src={Fibre} className="ligne img"></img>
                </a>
            </div>
            <div className='Spacing'></div>
            <br />
            <h1>Equipement :</h1>
            <br />
            <div className='joiner'>
                <a href='Produits'>
                    <img src={Portable} className="ligne img"></img>
                </a>

                <a href='Produits#mobile'>
                    <img src={Mobile} className="ligne img"></img>
                </a>
                <a href='Produits#casque'>
                    <img src={Casque} className="ligne img"></img>
                </a>
            </div>
            <div className='Spacing'></div>
            <br />
            <h1>Services :</h1>
            <div>
            <a href='Offres'>
                    <img src={Musiquedatt} className="ligne imgSPE"></img>
                </a>
            </div>
        </div>
        <div className='Spacing'></div>
        <Footer />
    </>)
}
export default Service;