import './styles/Pourquoi.css'
import Footer from '../Components/Footer';
import Header from './Header'
import expert from './img/Pourquoi/expert.jpg';
import DD from './img/Pourquoi/DD.jpg';
import rapide from './img/Pourquoi/rapide.jpg';
import robot from './img/Pourquoi/robot.jpg';
import support from './img/Pourquoi/support.jpg';
function Service() {
    return (<>
        <Header />
        <div className='widget ServiceCtnr'>
            <div  className='spacing'></div>
            <h1 className='Title'>Pourquoi T.C.S ?</h1>
         
            <div  className='Spacing'></div>
            <div  className='spacing'></div>
            <div>
                <h1>Expertise Technologique de T.C.S.</h1>
            </div>
            <div  className='Spacing'></div>
            <div className='rowalign'>
            <div><img className='max' src={expert}></img></div>
            <div>
                <h3>
                    T.C.S. est reconnue pour son expertise en technologie, avec plus de treize ans d'expérience dans l'industrie. Elle fournit des solutions innovantes et efficaces pour les entreprises de toutes tailles, aidant ainsi à surmonter les défis technologiques les plus complexes.
                </h3>
            </div>
            </div>
            <div  className='Spacing'></div>
            <div>
                <h1>Service Clientèle de Qualité Supérieure</h1>
            </div>
            <div  className='Spacing'></div>  
            <div className='rowalign'>
            <div>
                <h3>L'entreprise offre un service à la clientèle de qualité supérieure, en se consacrant à fournir des solutions personnalisées qui répondent aux besoins spécifiques de chaque client. Ses représentants sont toujours prêts à aider les clients à résoudre les problèmes techniques qui pourraient survenir.</h3>
            </div>
            <img className='max' src={support}></img>
            </div>
            <div  className='Spacing'></div>
            <div>
            <h1>Innovation Continue de T.C.S.</h1>
            <div  className='Spacing'></div>
            <div className='rowalign'>
            <div><img className='max' src={robot}></img></div>
            <div>
                <h3>T.C.S. est axée sur l'innovation et recherche constamment de nouvelles façons d'améliorer ses produits et services pour répondre aux besoins changeants des clients. Elle investit dans la recherche et le développement pour proposer des solutions de pointe qui donnent à ses clients un avantage concurrentiel.</h3>
            </div>
            </div>
            <div  className='Spacing'></div>
            <div>
                <h1>Responsabilité Sociale de T.C.S.</h1>
            </div>
            <div  className='Spacing'></div>
            <div className='rowalign'>
            <div>
                <h3> L'entreprise est également engagée dans la responsabilité sociale, étant active dans de nombreux projets de développement durable et de bienfaisance dans les communautés où elle opère. Les clients peuvent se sentir bien en sachant qu'ils travaillent avec une entreprise qui se soucie de faire une différence positive dans le monde.</h3>
            </div>
            <div><img className='max' src={DD}></img></div>
            </div>
            <div  className='Spacing'></div>
            <h1>En somme</h1>
            <div  className='Spacing'></div>
            <div className='rowalign'>
            <div><img className='max' src={rapide}></img></div>
            <div>
                <h3>T.C.S. est le choix parfait pour toute entreprise en quête de solutions informatiques de pointe.<br /> Elle offre un service technique fiable, compétent et rapide, en plus d'être un partenaire de confiance pour vous aider à réussir.</h3>
            </div>
            <div  className='Spacing'></div>
        </div>
        </div>
        </div>
        <Footer />
    </>)
}
export default Service;