import logoC from "../Components/img/logoTCS.png";
import React, { useState } from "react";
import Header from './Header';
import Interval from "../Acceuil";
import logo from "../Components/img/newtcsbcg.png";
import "./styles/Produits.css"
import Card from "./Card"
import Footer from "./Footer";
function Produits() {
   
  const [Over, setOver] = useState(true)
  const [Over1, setOver1] = useState(true)
  const [Over2, setOver2] = useState(true)
  const [Over3, setOver3] = useState(true)
  const [Over4, setOver4] = useState(true)
  const [Over5, setOver5] = useState(true)
  const [Over6, setOver6] = useState(true)
  const [Over7, setOver7] = useState(true)

  // function CardInfo1() {
  //   setOver1(!Over1)
  //   console.log('Card 2');
  //   if (Over1 === false) {
  //     setInterval(CardInfo1, 3000)
  //   }
  // }
  // function CardInfo2() {
  //   setOver2(!Over2)
  //   console.log('Card 3');
  //   if (Over2 === false) {
  //     setInterval(CardInfo2, 3000)
  //   }
  // }
  // function CardInfo3() {
  //   setOver3(!Over3)
  //   console.log('Card 4');
  //   if (Over3 === false) {
  //     setInterval(CardInfo3, 3000)
  //   }
  // }
  // function CardInfo4() {
  //   setOver4(!Over4)
  //   console.log('Card 5');
  //   if (Over4 === false) {
  //     setInterval(CardInfo4, 3000)
  //   }
  // }
  // function CardInfo5() {
  //   setOver5(!Over5)
  //   console.log('Card 6');
  //   if (Over5 === false) {
  //     setInterval(CardInfo5, 3000)
  //   }
  // }
  // function CardInfo6() {
  //   setOver6(!Over6)
  //   console.log('Card 7');
  //   if (Over6 === false) {
  //     setInterval(CardInfo6, 3000)
  //   }
  // }
  // function CardInfo7() {
  //   setOver7(!Over7)
  //   console.log('Card 8');
  //   if (Over7 === false) {
  //     setInterval(CardInfo7, 3000)
  //   }
  // }
  return (
    <>
      <Header />
      <div className='icon'>
        <img src={logo} className="resz"></img>
      </div>
      <br></br>
      <div className='widget Cards'>
        <h1 className="Title">T.C.S Propose une large gamme de produits</h1>
        <br></br><br /><br />
        <h1>téléphonie filaire/sans-fil :
        </h1>
        <br /><br />
                 <Card/>
      </div >
        <br /><br />
      <Footer/>
    </>
  );
}

export default Produits;
