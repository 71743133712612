import './styles/Pages.css'
import Imgmapper from '../Components/Imgmapper';
import wifi from "../Components/img/wifilogo.png";
import logo from "../Components/img/newtcsbcg.png"
import tools from "../Components/img/toolslogo.png";
import light from "../Components/img/lightbolt.png";
import tour from '../Components/img/Acceuil/communications-tower.webp';
import touracceuil from '../Components/img/touracceuil.jpg';
import engagement from '../Components/img/Acceuil/handshaking.webp';
import team from '../Components/img/Acceuil/group-of-business-workers-standing-with-hands-together-at-the-office.webp';


function Pages() {
  return (<>
    <div className='anime' style={{ zIndex: 1 }}></div>
    <div className='Def'>
      <div className='ficon'>
        <img src={logo} className="resz"/>
      </div>
      <h3 className='wht'>Technologie . Communication . System</h3>
      <div className='widget thk'>
        <div className='icon'>
          <div className='ico' ><img src={wifi} className='logs'></img><h5 className='pad'>Fiabilité</h5></div>
          <div className='ico'><img src={tools} className='spe'></img><h5>Intervention</h5></div>
          <div className='ico'><img src={light} className='spe'></img><h5>Rapidité</h5></div>
        </div>
      </div>
      <div className='widget-spe'>
        <div className='thkalt'>
          <div className='paadd'>
            <div className='tourwrapper'>
            <img src={touracceuil} className='openimg topp'></img>
            </div>
          </div>
          <div className='clmn4text'>
            <h2><u>T.C.S, votre partenaire privilégié pour toutes vos exigences en matière de télécommunications, informatique et solutions métiers.</u></h2>
            <h5>Forts d'une expérience depuis 2008, notre mission consiste à optimiser vos échanges grâce à des innovations sur mesure, libérant ainsi votre énergie pour vous concentrer sur l'essentiel. D'origine française, notre équipe à taille humaine est animée par une passion commune : vous aider à tirer parti de la technologie pour booster votre entreprise.<br /><br />
              En tant qu'acteur indépendant des opérateurs téléphoniques, nous nous positionnons en tant que garants de vos solutions de communication. Cela nous permet de vous proposer les meilleurs services et tarifs avantageux.<br /><br />
              Que ce soit pour la téléphonie VOIP, les logiciels de communication unifiée ou les connexions internet à très haut débit, toutes nos solutions sont conçues pour être interconnectées. Notre objectif : vous offrir une productivité accrue et une fluidité sans pareille dans votre quotidien professionnel.<br /><br />
              Avec T.C.S, la technologie devient votre alliée ultime pour propulser votre entreprise vers de nouveaux sommets.<br />
            </h5>
          </div>
        </div>
        <div className='spacing'></div>
        <div className='bluecard'>
          <Imgmapper/>
        </div>
        <div className='spacing'></div>
        <div className='widget-spe'>
              <h1>En quelques points</h1>
          <div className='thkalt '>
            <div className='cln-container'>
              <div className='cln'>
                
                <img src={tour} className='imgmiddle' />
                <h1>Nos services de télécommunications</h1>
                <h3>Nous proposons une large gamme de solutions, pour les entreprises de la region. Nous sommes également spécialisés dans les réseaux de télécommunications et la mise en place de solutions de téléphonique pour les professionnels.</h3>
            </div>
            <div className='clnalt'>
              <img src={team} className='padd deux' />
              <h1>Nos équipes</h1>
              <h3>N'hésitez pas à contacter notre équipe de professionnels expérimentés qui est à votre disposition pour vous aider à sélectionner les meilleures solutions.</h3>

            </div>
            <div className='clnalt2'>
              <img src={engagement} className='padd' />
              <h1>Nos engagements</h1>
              <h3>Nous offrons à tous nos clients des services de qualité et d'accompagnement tout au long de leur projet.</h3>
            </div>
              </div>
          </div>
        </div>
      </div >
    </div >
  </>)
}
export default Pages;