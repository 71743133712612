import App from '../App';
import { useFetcher } from 'react-router-dom';
import './styles/BookServices.css'
import { useState, useEffect } from 'react'
import Footer from './Footer';
import HomeBtn from './HomeBtn';
import Header from './Header';
import { HashLink } from 'react-router-hash-link';
import Ligneimg from './img/Circle/lignefibre.png';
import Voip from './img/Circle/Voip.png';
import Casque from './img/Circle/Casque.png';
import lignefibre from './img/Circle/lignefibre.png';
import Musiquedatt from './img/Circle/Musiquedatt.png';
import Portable from './img/Circle/portable.png';
import Support from './img/Circle/SupportEcoute.png';
import Fibre from './img/Circle/Fibre.png';
import vdsl from './img/Circle/vdsl.png';
import adsl from './img/Circle/adsl.png';
import Mobile from './img/Circle/Mobile.png';
import Security from './img/Security.png'
import Voip_pabx from './img/VOIP_PABX.png'
import './HomeBtn'
import './styles/Service.css'
import ImageSlider2 from './Slider/ImageSlider';
function Offres() {
  return (<>
    <Header />
    <HomeBtn />
    <div className='widget'>
      <div className='content'>
        <div className='text'>
          <h1>Offres</h1>
          <br />
          <br />
          <h1><u>Musique d'attente</u></h1>
          <br />
          <br />
          <h3 className='speH'> Découvrez notre service de musique d'attente professionnel sur mesure. Laissez-vous immerger dans une expérience pré-décrocher unique, où chaque morceau est soigneusement sélectionné pour captiver vos appelants. Offrez-leur une atmosphère personnalisée et professionnelle, grâce à notre vaste bibliothèque musical.</h3>
          <br />
          <br />
          <h1><u>connecter ma téléphonie :</u></h1>
          <br />
          <img src={Voip_pabx} />
          <br />
          <br />
          <br />
          <section id='vdsl'>
          <br />
          <br />
            <h1 id='vdsl'>Vdsl:</h1>
            <br />
            <br />
            <h3 className='speH'> La VDSL (Very High Bitrate Digital Subscriber Line) est une évolution de l'ADSL qui permet d'obtenir des débits de données plus élevés en utilisant la même infrastructure de câbles de cuivre existante. La principale différence entre l'ADSL et la VDSL réside dans la technologie de modulation utilisée pour transmettre les signaux sur les fils de cuivre.<br />

              La VDSL utilise des fréquences plus élevées que l'ADSL, ce qui lui permet de transporter davantage de données sur une distance plus courte. Cela signifie que les utilisateurs qui se trouvent près du central téléphonique peuvent bénéficier de débits de données plus rapides par rapport à l'ADSL.<br /><br /> En revanche, les utilisateurs situés plus loin du central peuvent voir leurs performances diminuer plus rapidement qu'avec l'ADSL.<br />

              La VDSL est souvent utilisée dans les zones densément peuplées où le déploiement de la fibre optique peut être plus complexe ou coûteux.<br /> Elle offre des débits plus élevés que l'ADSL traditionnel, ce qui en fait une solution intéressante pour ceux qui souhaitent une connexion Internet plus rapide sans avoir accès à la fibre optique.</h3>
            <br />
          </section>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
    <Footer />
  </>)
}
export default Offres;