import './styles/Cards.css';
import Pages from './Pages';
import React, { useState } from "react";
//User connecté ?
//if false, display connect,
//if true, vérifie widget associé au user
// récupère id user
//va dans table user_has_widget
//check table user_has_widget and use widget_id
//display list of widget



function Cards(){
const [arrayWidget, setArrayWidget] = useState([]);
console.log(arrayWidget);
function getwidget(){ return(   
<>  

</>
)}


return(<>
  <div>
        <div>
                <Pages/>
            </div>
        </div>
    </>)
}

export default Cards;