import React, { useState, useEffect} from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Cards from './Components/Cards';
import Footer from './Components/Footer';
import images from "./Components/Slider/Image";
import ImageSlider from "./Components/Slider/ImageSlider";
import './Components/styles/BookServices.css'

function Acceuil() {
  const [ModalLogin, setModalLogin] = useState(false);
  const [Interval, setInterval] = useState(false);  

  
  useEffect(() => {
    let timeoutRef = setTimeout(()=> setModalLogin(true), 2000)
    return ()=> timeoutRef && clearTimeout(timeoutRef)
  }, [])
  
  
  return (
    <>
      <div className="Both">
        {ModalLogin ? (<>
        <Header />
    <ImageSlider/>
             <Cards />
             <div className='Spacing'/>
             <Footer />
             </>) : null}
      </div>
    </>
  );
}

export default Acceuil;
