import App from '../App';
import { useFetcher } from 'react-router-dom';
import './styles/BookServices.css';
import { useState, useEffect } from 'react';
import Footer from './Footer';
import HomeBtn from './HomeBtn';
import Header from './Header';
import { HashLink } from 'react-router-hash-link';
import Voip_pabx from './img/VOIP_PABX.png';
import './HomeBtn';
import './styles/Service.css';

function Fibre_Adsl() {
  return (
    <>
      <Header />
      <HomeBtn />
      <div className='widget'>
        <div className='content'>
          <div className='text'>
            <h1>Fibre / Adsl</h1>
            <br />
            <br />
            <h1><u>Fibre & Adsl: Les différences :</u></h1>
            <br />
            <br />
            <div className='contain'>
              <h3 className='speH'>
                La fibre optique est une technologie de communication qui utilise des fils de verre ou de plastique pour transmettre des signaux lumineux.
                <br />
                <br />
                Elle offre des débits de données élevés et une stabilité de connexion supérieure par rapport à l'ADSL (Asymmetric Digital Subscriber Line), qui utilise les fils de cuivre existants du réseau téléphonique.
                <br />
                <br />
                La fibre optique permet une transmission plus rapide des informations en convertissant les données en signaux lumineux qui peuvent voyager sur de longues distances sans perte de qualité.
              </h3>
            </div>
            <br />
            <br />
            <h1><u>Connecter ma téléphonie :</u></h1>
            <br />
            <img src={Voip_pabx} alt="Voip_pabx" />
            <br />
            <br />
            <br />
            <section id='vdsl'>
              <br />
              <br />
              <h1 id='vdsl'>Vdsl:</h1>
              <br />
              <br />
            <div className='contain'>
              <h3 className='speH'>
                La VDSL (Very High Bitrate Digital Subscriber Line) est une évolution de l'ADSL qui permet d'obtenir des débits de données plus élevés en utilisant la même infrastructure de câbles de cuivre existante. La principale différence entre l'ADSL et la VDSL réside dans la technologie de modulation utilisée pour transmettre les signaux sur les fils de cuivre.
                <br />
                <br />
                La VDSL utilise des fréquences plus élevées que l'ADSL, ce qui lui permet de transporter davantage de données sur une distance plus courte. Cela signifie que les utilisateurs qui se trouvent près du central téléphonique peuvent bénéficier de débits de données plus rapides par rapport à l'ADSL.
                <br />
                <br />
                En revanche, les utilisateurs situés plus loin du central peuvent voir leurs performances diminuer plus rapidement qu'avec l'ADSL.
                <br />
                <br />
                La VDSL est souvent utilisée dans les zones densément peuplées où le déploiement de la fibre optique peut être plus complexe ou coûteux. Elle offre des débits plus élevés que l'ADSL traditionnel, ce qui en fait une solution intéressante pour ceux qui souhaitent une connexion Internet plus rapide sans avoir accès à la fibre optique.
              </h3>
              <br />
              </div>
            </section>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Fibre_Adsl;
