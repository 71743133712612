import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import { HashLink } from 'react-router-hash-link';
import Acceuil from './Acceuil';
import Pages from './Components/Pages';
import Contact from './Components/Contact';
import Produits from './Components/Produits';
import Header from './Components/Header'
import Card from './Components/Card';
import Service from './Components/Service';
import Pourquoi from './Components/Pourquoi';
import Login from './Components/Login'
import Book from './Components/Book'
import Offres from './Components/Offres'
import BookServices from './Components/BookServices'
import Sign from './Components/Sign'
import Fibre_Adsl from './Components/Fibre_Adsl'
function App() {
  return (
    <>
       
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Acceuil />} />
            <Route path="/Header" element={<Header />} />
            <Route path='/pages' element={<Pages />} />
            <Route path='/Contact' element={<Contact/>} />
            <Route path='/Produits' element={<Produits/>} />
            <Route path='/Card' element={<Card/>} />
            <Route path='/Service' element={<Service/>} />
            <Route path='/Pourquoi' element={<Pourquoi/>} />
            <Route path='/Login' element={<Login/>} />
            <Route path='/Book' element={<Book/>} />
            <Route path='/BookServices' element={<BookServices/>} />
            <Route path='/Sign' element={<Sign/>} />
            <Route path='/Fibre_Adsl' element={<Fibre_Adsl/>} />
            <Route path='/Offres' element={<Offres/>} />
          </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
