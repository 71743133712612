import logoC from "../Components/img/logoTCS.png";
import './styles/Footer.css';
import React, { useState } from "react";
import alcatel from './img/alcalucent.webp';
import yeahlink from './img/yeahlink.webp';

function Footer() {
  const [ModalPannel, setModalPannel] = useState(false);
  return (
    <>
      <div className="PageRest" />
      <div className='footer'>
        <div className='main'>
          <div className="Background">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
          <div className="Logo">
            <img src={logoC} onClick={() => setModalPannel(!ModalPannel)} className='bimg'></img>
          </div>
          <div className="Sponsor">
            {/* <div className="Partenaire clndisplay">
              <h1 className="sponsorTitle">Nos partenaire :</h1>
              <div className="row">
                <img className="alcaicon" src={alcatel} />
                <img className="yeahlinkicon" src={yeahlink} />
              </div>
            </div> */}
            <div className="verticalspacing"></div>
          </div>
          <div className='Rest'>
          <button className='button-connect'>Contactez-nous</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
