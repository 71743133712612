import logoC from "../Components/img/logoTCS.png";
import React, { useState } from "react";
import Header from  './Header';
import logo from "./img/newtcsbcg.png";
import imgbtn from './img/homebtnimg.png'
import './styles/HomeBtn.css'
function HomeBtn() {
    return(
        <a href="/">

            <img className="homebutton" src={imgbtn}/> 
        </a>
      
    )
}
export default HomeBtn;