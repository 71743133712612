import React from 'react';
import ReactCardFlip from 'react-card-flip';
import tel from "../Components/img/Phone/mp56etEXP50.png";
import tel1 from "../Components/img/Phone/T55A.png";
import tel2 from "../Components/img/Phone/t46u.jpg";
import tel3 from "../Components/img/Phone/t54w.png";
import tel4 from "../Components/img/Phone/t58a.jpg";
import tel5 from "../Components/img/Phone/W59R.jpg";
import tel6 from "../Components/img/Phone/vp59.jpg";
import tel7 from "../Components/img/Phone/CP960.jpg";
import tel8 from "../Components/img/Phone/iphone15.png";
import casque65 from "./img/Phone/casque65.avif";
import casque930 from "./img/Phone/casque930.avif"
import "./styles/Card.css"
import { HashLink } from 'react-router-hash-link';
class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
      isFlipped1: false,
      isFlipped2: false,
      isFlipped3: false,
      isFlipped4: false,
      isFlipped5: false,
      isFlipped6: false,
      isFlipped7: false,
      isFlipped8: false,
      isFlipped9: false,
      isFlipped10: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClick1 = this.handleClick1.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClick3 = this.handleClick3.bind(this);
    this.handleClick4 = this.handleClick4.bind(this);
    this.handleClick5 = this.handleClick5.bind(this);
    this.handleClick6 = this.handleClick6.bind(this);
    this.handleClick7 = this.handleClick7.bind(this);
    this.handleClick8 = this.handleClick8.bind(this);
    this.handleClick9 = this.handleClick9.bind(this);
    this.handleClick10 = this.handleClick10.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }
  handleClick1(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped1: !prevState.isFlipped1 }));
  }
  handleClick2(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped2: !prevState.isFlipped2 }));
  }
  handleClick3(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped3: !prevState.isFlipped3 }));
  }
  handleClick4(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped4: !prevState.isFlipped4 }));
  }
  handleClick5(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped5: !prevState.isFlipped5 }));
  }
  handleClick6(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped6: !prevState.isFlipped6 }));
  }
  handleClick7(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped7: !prevState.isFlipped7 }));
  }
  handleClick8(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped8: !prevState.isFlipped8 }));
  }
  handleClick9(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped9: !prevState.isFlipped9 }));
  }
  handleClick10(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped10: !prevState.isFlipped10 }));
  }
  render() {
    return (<>
      <div className="twoSide">
        <ReactCardFlip isFlipped={this.state.isFlipped}>
          <div className='frontcard'>
            <img
              alt='img1'
              className='frontcardimg'
              onMouseOver={this.handleClick}
              src={tel}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick}>
            <div
            />
            <h1>
              PurpRight
            </h1>
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              +
              <br />
              <br />
              Deck d'extension
              <br />
              <br />
              conçu pour étendre la capacité fonctionnelle de votre téléphone
            </h3>
          </div>
        </ReactCardFlip>
        <div className='Vspacing' />
        <ReactCardFlip isFlipped={this.state.isFlipped1}>
          <div className='frontcard'>
            <img
              alt='img2'
              className='frontcardimg'
              onMouseOver={this.handleClick1}
              src={tel1}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick1}>
            <div
            />
            <h1>
              SteelVersion Teams/Skype
            </h1>
            <br />
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              Version avec
              <br />
              Teams ou Skype intégré
            </h3>
          </div>
        </ReactCardFlip>
        <div className='Vspacing' />
        <ReactCardFlip isFlipped={this.state.isFlipped2}>
          <div className='frontcard'>
            <img
              alt='img3'
              className='frontcardimg_spe'
              onMouseOver={this.handleClick2}
              src={tel2}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick2}>
            <div
            />
            <h1>
              BackLight deepBlack
            </h1>
            <br />
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              <br />
              <br />
              Sobre, rétro-éclairé
            </h3>
          </div>
        </ReactCardFlip>
      </div>
      <div className="twoSide">
        <ReactCardFlip isFlipped={this.state.isFlipped3}>
          <div className='frontcard'>
            <img
              alt='img4'
              className='frontcardimg'
              onMouseOver={this.handleClick3}
              src={tel3}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick3}>
            <div
            />
            <h1>
              LargeScreen
            </h1>
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              +
              <br />
              <br />
              Un Large ecran et une nouvelle interface
            </h3>
          </div>
        </ReactCardFlip>
        <div className='Vspacing' />
        <br />
        <ReactCardFlip isFlipped={this.state.isFlipped4}>
          <div className='frontcard'>
            <img
              alt='img5'
              className='frontcardimg'
              onMouseOver={this.handleClick4}
              src={tel4}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick4}>
            <div
            />
            <h1>
              Tactil-LargeScreen
            </h1>
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              <br />
              <br />
              Un large ecran tactile et une interface en fonction
              <br />
              <br />
            </h3>
          </div>
        </ReactCardFlip>
        <div className='Vspacing' />
        <ReactCardFlip isFlipped={this.state.isFlipped5}>
          <div className='frontcard'>
            <img
              alt='img6'
              className='frontcardimg_spe'
              onMouseOver={this.handleClick5}
              src={tel5}
            />
          </div>
          <div className='backcard' onMouseLeave={this.handleClick5}>
            <div
            />
            <h1>
              Wireless
            </h1>
            <br />
            <h3>
              Téléphonie sans-fil
              <br />
              (voip)
              <br />
              <br />
              Téléphone sans-fil
              <br />
              waterproof/antichoc
              <br />
              <br />
              portée de 50m en intérieur et 300m en champs libre
            </h3>
          </div>
        </ReactCardFlip>
      </div>
      <div className="twoSideconf">
        <ReactCardFlip isFlipped={this.state.isFlipped6}>
          <div className='frontcard'>
            <img
              alt='img7'
              className='frontcardimg'
              onMouseOver={this.handleClick6}
              src={tel6}
            />
          </div>

          <div className='backcard' onMouseLeave={this.handleClick6}>
            <div
            />
            <h1>
              Tactil-LargeScreen + Caméra
            </h1>
            <br />
            <h3>
              Téléphonie filaire
              <br />
              (voip)
              <br />
              <br />
              <br />
              Un large ecran tactile et une interface en fonction
              <br />
              <br />
              ainsi qu'une caméra pour vos Visio-conférences
              <br />
            </h3>
          </div>
        </ReactCardFlip>
      </div>
      <br></br>
      <br></br>
      <h1>Conférence :</h1>
      <div className="twoSideconf undered">

        <HashLink to="/Produits#casque">
          <ReactCardFlip isFlipped={this.state.isFlipped7}>
            <div className='frontcard'>
              <img
                alt='img8'
                className='frontcardimg'
                onMouseOver={this.handleClick7}
                src={tel7}
              />
            </div>

            <div className='backcard' onMouseLeave={this.handleClick7}>
              <div
              />
              <h1>
                CompagnyMeeting
              </h1>
              <br />
              <h3>
                Téléphonie filaire + Bluetooth
                <br />
                (voip)
                <br />
                <br />
              </h3>
              <h3>
                Converser, j'usqu'a 10 participants <br />
                en salle de réunion ou via votre smartphone.
              </h3>
            </div>
          </ReactCardFlip>
        </HashLink>
      </div>
      <br />
      <h1>Casques :</h1>
        <HashLink to="/Produits#casques">
      <div className='twoSideconf casque'>
          <br />
          <ReactCardFlip isFlipped={this.state.isFlipped8}>
            <div className='frontcard casque'>
              <img
                alt='img5'
                className='frontcardimg'
                onMouseOver={this.handleClick8}
                src={casque930}
              />
            </div>
            <div className='backcard' onMouseLeave={this.handleClick8}>
              <div
              />
              <h1>
                Casque Jabra pro
              </h1>
              <br />
              <h3>
                Téléphonie Fixe
                <br />
                (voip) 
                <br />
                <br />
              </h3>
              <h3>
                Casque dernier cris professionel, pour vos bureaux
              </h3>
            </div>
          </ReactCardFlip>
          <ReactCardFlip isFlipped={this.state.isFlipped9}>
            <div className='frontcard'>
              <img
                alt='img5'
                className='frontcardimg'
                onMouseOver={this.handleClick9}
                src={casque65}
              />
            </div>
            <div className='backcard' onMouseLeave={this.handleClick9}>
              <div
              />
              <h1>
                Casque Jabra 
              </h1>
              <br />
              <h3>
                Casque audio 
                <br />
                (voip)
                <br />
                <br />
              </h3>
              <h3>
              Casque dernier cris, pour vos bureaux
              </h3>
            </div>
          </ReactCardFlip>
      </div>
        </HashLink>
      <h1>Mobiles :</h1>
        <HashLink to="/Produits#mobile">
      <div className='twoSide mobiles'>
          <br />
          <ReactCardFlip isFlipped={this.state.isFlipped10}>
            <div className='frontcard'>
              <img
                alt='img5'
                className='frontcardimg'
                onMouseOver={this.handleClick10}
                src={tel8}
              />
            </div>
            <div className='backcard' onMouseLeave={this.handleClick10}>
              <div
              />
              <h1>
                Iphone Derniere Generation
              </h1>
              <br />
              <h3>
                Téléphonie mobile
                <br />
                (voip)
                <br />
                <br />
              </h3>
              <h3>
                Smartphone dernier cris, et toute les nouvelles innovation qui vont avec
              </h3>
            </div>
          </ReactCardFlip>
      </div>
        </HashLink>
    </>
    );
  }
}

export default Card;