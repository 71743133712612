import React, {  } from 'react';
import './Style.css'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module

export default () => {
  const [sliderRef] = useKeenSlider({
    loop: {
      min: -5,
      max: 5,
    },
  })
  return (
    <div ref={sliderRef} className="keen-slider">
      <div className="keen-slider__slide number-slide1"></div>
      <div className="keen-slider__slide number-slide2"></div>
      <div className="keen-slider__slide number-slide3"></div>
      <div className="keen-slider__slide number-slide4"></div>
      <div className="keen-slider__slide number-slide5"></div>
    </div>
  )
}