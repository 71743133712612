import React, { useState, useEffect } from 'react';
import vue from '../Components/img/vue-generale-de-marseille_1920x600.jpg';
import openS from '../Components/img/openspace.webp';
import network from '../Components/img/network.jpg';
import blank from '../Components/img/blank.png';
import '../Components/styles/imgmapper.css';
function Imgmapper() {
    const [display, setDisplay] = useState(vue);
    const [textdisplay, setTextdisplay] = useState(<></>);
    const setValue = (i) => {
        if (i === 1) {
            setDisplay(vue);
            setTextdisplay(<>Nichés au-dessus de Marseille, chez T.C.S.<br /><br />notre emplacement privilégié au cœur de la ville offre une proximité inestimable à nos clients.
                <br /><br /> Cette localisation stratégique renforce notre réactivité et crée des relations privilégiées avec notre clientèle locale.
                <br /><br />Profitez de la facilité d'accès et de la connexion spéciale que notre positionnement géographique unique apporte à nos services.
                <br /><br />Découvrez comment notre vue imprenable sur Marseille se traduit par une expérience client exceptionnelle, ancrée dans la proximité et la compréhension de vos besoins.
                <br /></>)
        } else if (i === 2) {
            setDisplay(openS);
            setTextdisplay(<>Un endroit en une méthode de travail irréprochable.<br /><br />
                Chez T.C.S., nos locaux incarnent l'excellence opérationnelle et la qualité de notre environnement de travail. Imprégnés d'une atmosphère dynamique et professionnelle, nos espaces sont conçus pour favoriser l'efficacité et l'innovation.
                <br /><br />Chaque détail de nos installations reflète notre engagement envers un standard élevé, assurant ainsi un lieu propice à la créativité et à la collaboration.
                <br /><br /> Rejoignez-nous dans nos locaux où l'excellence devient une méthode de travail quotidienne.<br /></>)
        } else if (i === 3) {
            setDisplay(network);
            setTextdisplay(<>Un réseau toujours plus rapide et constant.<br /><br />
                Chez T.C.S., notre engagement envers la rapidité ne se limite pas à nos services, il s'étend également à la manière dont nous opérons. Notre réseau, constamment optimisé, assure des performances rapides et fiables.
                <br /><br />La rapidité devient ainsi une caractéristique fondamentale de notre approche, garantissant une réactivité exceptionnelle à chaque étape de notre collaboration.<br /></>)
        }
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Change the value automatically every 5 seconds
            if (display === blank) {
                setValue(1);
            } else if (display === vue) {
                setValue(2);
            } else if (display === openS) {
                setValue(3);
            } else if (display === network) {
                setValue(1);
            }
        },7000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [display, setValue]);



    return (
        <>
            <div className='center'>
                <div className='sizedefiner'>

                <div className='rowalign'>
                    {display === network ? (
                        <img className='reduce network' src={display} alt="displayed image" />
                    )
                        : display === vue ? (
                            <>
                            <img className='vue' src={display} alt="displayed image" />
                            </>
                     
                        )
                        : (
                            <img className='reduceopen' src={display} alt="displayed reducer" />
                            )}
                            <div className='Verticalseparator'></div>
                    <div className='leftText'>{textdisplay}</div>
                </div>
                            </div>
                <div className='rowalign'>
                    <button className={display === vue ? 'imgselector blanckbutton' : 'imgselector'} onClick={() => setValue(1)}>Où sommes-nous ?</button>
                    <button className={display === openS ? 'imgselector blanckbutton' : 'imgselector'} onClick={() => setValue(2)}>Nos locaux</button>
                    <button className={display === network ? 'imgselector blanckbutton' : 'imgselector'} onClick={() => setValue(3)}>Comment êtes-vous si rapide ?</button>
                </div>
            </div>
        </>
    );
}

export default Imgmapper;