import logoC from "../Components/img/logoTCS.png";
import React, { useState } from "react";
import Header from  './Header';
import logo from "./img/newtcswhitelogo.png";
import Footer from "./Footer";
import './styles/Contact.css';
function Contact() {
  return (
    <>
  <Header/>
  <div className='icon'>
    <img src={logo} className="resz"></img>
    </div>
  <br></br>
    <div className='widget Cards'>
    <h3>T.C.S est ouvert du lundi au vendredi de 9h à 18h</h3>
    <h5>(en dehors de ces horaires vous pouvez adresser un mail expliquant les problèmes rencontrés sur votre installation à : support@tcs-group.fr)</h5>
    <br></br>
    <h1>Administratif :
    </h1>
    <a href="tel:+33491371660"><h3>0491371660</h3></a>
    <a href='mailto:adm@tcs-group.fr'><h3>adm@tcs-group.fr</h3></a>
  <br></br>
  <br></br>
    <h1>Support technique :</h1>
    <a href="tel:+33491671819"><h3>0491671819</h3></a>
    <a href='mailto:support@tcs-group.fr'><h3>support@tcs-group.fr</h3></a>
    </div>
    <Footer/>
  </>
  );
}

export default Contact;
