import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select'
import './styles/BookServices.css';
import Footer from './Footer';
import HomeBtn from './HomeBtn';
import Header from './Header';
import Menu from './Menu';
import Security from './img/Security.png';
import PartageAndPhone from './img/PartageAndPhone.png';
import PPS from './img/PartageVoipandSecurity.png';
import PaS from './img/PaS.png';
import Pho from './img/VOIPOnly.png';
import Pa from './img/PartageOnly.png';
import Secu from './img/SecOnly.png';
import VS from './img/VoipandSecurity.png';
import plus from './img/plus.png';
import less from './img/less.png';
import btnsign from './img/buttonsign.png';
import checked from './img/checkd.png';
import './styles/sign.css';
import { FormCheck } from 'react-bootstrap';
import Formulaire from './Formulaire';
function Form() {
  return (
    <div>
      <h2>Form Data:</h2>
      <Formulaire />
    </div>
  );
};







const phones = [
  { value: 'mp56etEXP50', label: 'mp56etEXP50', image: './src/Components/img/mp56etEXP50.png' },
  { value: 'T55A', label: 'T55A', image: './src/Components/img/T55A.png' },
  { value: 't54w', label: 't54w', image: './src/Components/img/t54w.png' },
  { value: 't58a', label: 't58a', image: './src/Components/img/t58a.png' },
  { value: 'W59R', label: 'W59R', image: './src/Components/img/W59R.png' },
  { value: 'vp59', label: 'vp59', image: './src/Components/img/vp59.png' },
  { value: 'CP960', label: 'CP960', image: './src/Components/img/CP960.png' }
];

function Service() {
  const [telephone, setTelephone] = useState(false);
  const [partage, setPartage] = useState(false);
  const [security, setSecurity] = useState(false);
  const [Menu, setMenu] = useState(false);
  const [MenuP, setMenuP] = useState(false);
  const [visible, setVisible] = useState(false)
  const [scroll, setScroll] = useState(0)
  const [scrollpadding, setScrollpadding] = useState(0)
  const [Rappel, setRappel] = useState(false)
  const [Materiel, setMateriel] = useState(false)
  const [SystemInfo, setSystemInfo] = useState(false)
  const [Row, setRow] = useState(0)
  const [selectedValues, setSelectedValues] = useState([['', '']]);

  const Button = ({ name, onClick, isActive }) => {
    const style = {
      background: isActive ? '#04b88' : 'transparent',
      color: '#044b88',
      borderColor: isActive ? 'white' : '#044b88',
    };
  }
  const addRow = () => {
    setRow(Row + 1);
    setSelectedValues([...selectedValues, ['', '']]);
    console.log(Row);
  }
  const lessRow = () => {
    setRow(Row - 1);
    setSelectedValues([...selectedValues, ['', '']]);
    console.log(Row);
  }
  const handleSelectChange = (rowIndex, selectIndex, value) => {
    const updatedValues = [...selectedValues];
    updatedValues[rowIndex][selectIndex] = value;
    setSelectedValues(updatedValues);
  };

  const handleClickTelephone = () => {
    setTelephone(!telephone);
  };

  const handleClickPartage = () => {
    setPartage(!partage);
  };

  const handleClickSecurity = () => {
    setSecurity(!security);
  };

  const renderImage = () => {
    if (telephone && !partage && !security) {
      return (<>
        <select className='phone-selector' placeholder='Select Phone'>
          <option value={'Choix du commercial'}>
            Laisser le choix du téléphone au commercial
          </option>
          <option value={'mp56etEXP50'}>mp56etEXP50</option>
          <option value={'T55A'}>T55A</option>
          <option value={'t54w'}>t54w</option>
          <option value={'t58a'}>t58a</option>
          <option value={'W59R'}>W59R</option>
          <option value={'vp59'}>vp59</option>
          <option value={'CP960'}>CP960</option>
        </select>
        <select className='number' id='nbrLignes'>
          {Array.from({ length: 50 }, (_, index) => (
            <option value={index} key={index}>
              {index}
            </option>
          ))}
        </select>
        <div className='redclickable'>
        <img onClick={() => lessRow()} className='plus-resizer' src={less}></img>
          <img onClick={() => addRow()} className='plus-resizer' src={plus}></img>
        </div>
        <br />
        <h3><u>telephone</u></h3>
        <br />
        <p>Nous fournissons des services de téléphonie pour les entreprises. Nous offrons une gamme de services de téléphonie de haute qualité pour répondre aux besoins de votre entreprise.</p>
        <br />
        <img className='Imgstyle' src={Pho} alt="Pho" />
      </>);
    }
    else if (partage && !telephone && !security) {
      return (<>
        <div className='flex'>

          <br />
          <h3><u>Partage</u></h3>
          <br />
          <p> Nous offrons des solutions de partage pour faciliter la collaboration entre les membres d'une
            équipe. Nous fournissons des outils de partage de fichiers et de travail collaboratif en temps
            réel pour améliorer l'efficacité et la productivité des entreprises.</p>
          <br />
          <img className='Imgstyle' src={Pa} alt="Pa" />
        </div>
      </>);
    }
    else if (security && !telephone && !partage) {
      return (<>
        <select className='phone-selector' placeholder='Select Phone'>
          <option value={'Choix du commercial'}>
            Laisser le choix des caméras au commercial
          </option>
          <option value={'Cam1'}>Cam1</option>
          <option value={'Cam2'}>Cam2</option>
          <option value={'Cam3'}>Cam3</option>
          <option value={'Cam4'}>Cam4</option>
          <option value={'Cam5'}>Cam5</option>
          <option value={'Cam6'}>Cam6</option>
          <option value={'Cam7'}>Cam7</option>
        </select>
        <select className='number' id='nbrLignes'>
          {Array.from({ length: 50 }, (_, index) => (
            <option value={index + 1} key={index}>
              {index + 1}
            </option>
          ))}
        </select>
        <div className='redclickable'>
        <img onClick={() => lessRow()} className='plus-resizer' src={less}></img>
          <img onClick={() => addRow()} className='plus-resizer' src={plus}></img>
        </div>
        <div className='flex'>

          <br />
          <h3><u>Securité</u></h3>
          <br />
          <p> Nous fournissons des solutions de sécurité pour protéger les transactions financières et les données
            personnelles. Nous offrons des cartes de crédit et de débit hautement sécurisées avec la technologie de
            puce EMV pour empêcher les fraudes.</p>
          <br />
          <img className='Imgstyle' src={Security} alt="Security" />
        </div>
      </>);
    }
    else if (telephone && partage && !security) {
      return (<>
       {Array.from({ length: Row }, (_, rowIndex) => (
              <div className='flex' key={rowIndex}>
      <select className='phone-selector' placeholder='Select Phone'>
          <option value={'Choix du commercial'}>
            Laisser le choix du téléphone au commercial
          </option>
          <option value={'mp56etEXP50'}>mp56etEXP50</option>
          <option value={'T55A'}>T55A</option>
          <option value={'t54w'}>t54w</option>
          <option value={'t58a'}>t58a</option>
          <option value={'W59R'}>W59R</option>
          <option value={'vp59'}>vp59</option>
          <option value={'CP960'}>CP960</option>
        </select>
        <select className='number' id='nbrLignes'>
          {Array.from({ length: 50 }, (_, index) => (
            <option value={index} key={index}>
              {index}
            </option>
          ))}
        </select>
               
              </div>
            ))}
        <div className='redclickable'>
        <img onClick={() => lessRow()} className='plus-resizer' src={less}></img>
          <img onClick={() => addRow()} className='plus-resizer' src={plus}></img>
        </div>
        <div className='flex'>
          <br />
          <h3><u>Voip & partage </u></h3>
          <br />
          <p>Nous offrons des services de téléphonie et de partage pour les entreprises. Nous fournissons des solutions de communication de haute qualité et des outils de partage de fichiers pour améliorer l'efficacité et la productivité des entreprises.</p>
          <br />
          <img className='Imgstyle' src={PartageAndPhone} alt="PartageAndPhone" />
        </div>
      </>);
    } else if (security && partage && !telephone) {
      return (<>

        <div className='flex'>
          <br />
          <h3><u>Security & partage</u></h3>
          <br />
          <p> Nous offrons des solutions de partage et de sécurité pour les entreprises. Nous fournissons des outils de partage de fichiers et de travail collaboratif en temps réel, ainsi que des services de sécurité pour protéger vos données et votre entreprise contre les cyberattaques.</p>
          <br />
          <img className='Imgstyle' src={PaS} alt="PaS" />
        </div>
      </>);
    } else if (security && telephone && !partage) {
      return (<>
        <div>
            {/* Display rows based on the numRows state */}
            {Array.from({ length: Row }, (_, rowIndex) => (
              <div key={rowIndex}>
                {/* Phone Selector */}
                <select
                  className='phone-selector-min'
                  value={selectedValues[rowIndex][0]}
                  onChange={(e) => handleSelectChange(rowIndex, 0, e.target.value)}
                >
                  <option value={'Choix du commercial'}>
                    Laisser le choix du téléphone au commercial
                  </option>
                  <option value={'mp56etEXP50'}>mp56etEXP50</option>
                  <option value={'T55A'}>T55A</option>
                  <option value={'t54w'}>t54w</option>
                  <option value={'t58a'}>t58a</option>
                  <option value={'W59R'}>W59R</option>
                  <option value={'vp59'}>vp59</option>
                  <option value={'CP960'}>CP960</option>
                </select>
                <select className='number' id='nbrLignes'>
                  {Array.from({ length: 50 }, (_, index) => (
                    <option value={index} key={index}>
                      {index}
                    </option>
                  ))}

                </select>

                <select
                  className='phone-selector'
                  value={selectedValues[rowIndex][1]}
                  onChange={(e) => handleSelectChange(rowIndex, 1, e.target.value)}
                >
                  <option value={'Choix du commercial'}>
                    Laisser le choix de caméra au commercial
                  </option>
                  <option value={'Cam1'}>Cam1</option>
                  <option value={'Cam2'}>Cam2</option>
                  <option value={'Cam3'}>Cam3</option>
                  <option value={'Cam4'}>Cam4</option>
                  <option value={'Cam5'}>Cam5</option>
                  <option value={'Cam6'}>Cam6</option>
                  <option value={'Cam7'}>Cam7</option>
                </select>
                <select className='number' id='nbrLignes'>
                  {Array.from({ length: 50 }, (_, index) => (
                    <option value={index} key={index}>
                      {index}
                    </option>
                  ))}
                </select>
              </div>
            ))}

            {/* Button to add a new row */}
            <div className='redclickable'>
            <img onClick={() => lessRow()} className='plus-resizer' src={less}></img>
              <img onClick={addRow} className='plus-resizer' src={plus} alt='Add Row' />
            </div>
        </div>
        <br />
        <h3><u>Voip & Sécurité</u></h3>
        <br />
        <p>  Nous fournissons des services de VoIP et de sécurité pour les entreprises. Notre service de VoIP
          est facile à utiliser et peut être personnalisé pour répondre aux besoins de votre entreprise. Nous
          <br />
          offrons également une gamme de services de sécurité pour protéger vos données et votre entreprise
          contre les cyberattaques.</p>
        <img className='Imgstyle' src={VS} alt="VS" />
      </>);
    } else if (partage && telephone && security) {
      return (
        <>
          <div>
            {/* Display rows based on the numRows state */}
            {Array.from({ length: Row }, (_, rowIndex) => (
              <div key={rowIndex}>
                {/* Phone Selector */}
                <select
                  className='phone-selector'
                  value={selectedValues[rowIndex][0]}
                  onChange={(e) => handleSelectChange(rowIndex, 0, e.target.value)}
                >
                  <option value={'Choix du commercial'}>
                    Laisser le choix du téléphone au commercial
                  </option>
                  <option value={'mp56etEXP50'}>mp56etEXP50</option>
                  <option value={'T55A'}>T55A</option>
                  <option value={'t54w'}>t54w</option>
                  <option value={'t58a'}>t58a</option>
                  <option value={'W59R'}>W59R</option>
                  <option value={'vp59'}>vp59</option>
                  <option value={'CP960'}>CP960</option>
                </select>
                <select className='number' id='nbrLignes'>
                  {Array.from({ length: 50 }, (_, index) => (
                    <option value={index} key={index}>
                      {index}
                    </option>
                  ))}

                </select>

                <select
                  className='phone-selector'
                  value={selectedValues[rowIndex][1]}
                  onChange={(e) => handleSelectChange(rowIndex, 1, e.target.value)}
                >
                  <option value={'Choix du commercial'}>
                    Laisser le choix de caméra au commercial
                  </option>
                  <option value={'Cam1'}>Cam1</option>
                  <option value={'Cam2'}>Cam2</option>
                  <option value={'Cam3'}>Cam3</option>
                  <option value={'Cam4'}>Cam4</option>
                  <option value={'Cam5'}>Cam5</option>
                  <option value={'Cam6'}>Cam6</option>
                  <option value={'Cam7'}>Cam7</option>
                </select>
                <select className='number' id='nbrLignes'>
                  {Array.from({ length: 50 }, (_, index) => (
                    <option value={index} key={index}>
                      {index}
                    </option>
                  ))}
                </select>
              </div>
            ))}

            {/* Button to add a new row */}
            <div className='redclickable'>
            <img onClick={() => lessRow()} className='plus-resizer' src={less}></img>
              <img onClick={addRow} className='plus-resizer' src={plus} alt='Add Row' />
            </div>
            <br />
          </div>
          <div className='flex'>
            <h3>
              <u>Partage, Voip and Security</u>
            </h3>
            <p>
              Nous offrons des services de voix et de données sur IP en utilisant la technologie la plus
              récente pour fournir à nos clients des solutions de communication de haute qualité. Nous offrons
              également des solutions de sécurité informatique pour protéger les entreprises contre les menaces
              numériques actuelles.
            </p>
            <br />
          </div>
          <img className='Imgstyle' src={PPS} alt="PPS" />
        </>);
    }
  };
  const rappel = () => {
    setRappel(!Rappel)
  }
  const systemModal = () => {
    setSystemInfo(!SystemInfo)
  }
  const MatModal = () => {
    setMateriel(!Materiel)
  }
  const handleMenu = () => {
    setMenu(!Menu);
  }
  const handleMenuP = () => {
    setMenuP(!MenuP);
  }
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setScroll(scrolled)
    setScrollpadding(scrolled + 40)
    if (scrolled > 1) {
      setVisible(true)
    }
    else if (scrolled <= 1) {
      setVisible(false)
    }
  };
  const BtneStyle = {
    position: "absolute",
    top: scroll + "px",
    right: "0",
    width: "4%",
    display: visible ? 'inline' : 'none'
  }
  const BtncStyle = {
    position: "absolute",
    top: scroll + 800 + "px",
    right: '150px',
    bottom: '10',
    width: "7%",
    display: visible ? 'inline' : 'none',
  }
  const BtnpStyle = {
    position: "absolute",
    top: scrollpadding + "px",
    left: "23px",
    width: "4%",
    display: visible ? 'inline' : 'none'
  }
  const positionning = {
    position: "absolute",
    top: scroll + "px",
    right: "0",
    width: "2000px",
    height: "100%",

  }
  const positionningPmenu = {
    position: "absolute",
    top: scroll + "px",
    left: "250",
    width: "2000px",
    height: "100%",

  }
  window.addEventListener('scroll', toggleVisible);
  return (<>
    <HomeBtn />
    <div className='flex'>
      <div className='rightalign'>
        <a onClick={handleMenu}>
          <img id='menu' style={BtneStyle} src={btnsign}></img>
        </a>
      </div>
      <div className='leftalign'>
        <a onClick={handleMenuP}>
          <img id='plus' className='plus' style={BtnpStyle} src={plus}></img>
        </a>
      </div>
      {Menu ? (<>
        <div style={positionning}>
          <div className='flex2'>
            <div>
              <h1>
                Information client
              </h1>
            </div>
            <br />
            <div className='mid'>
              <h4>TEL:</h4>
              <input id='TEL'></input>
              <br />
              <h4>FAX:</h4>
              <input id='fax'></input>
              <br />
              <a onClick={MatModal}>
                <div className='classic'>
                  <h3>Materiel</h3>
                </div>
              </a>
              <br />
              {Materiel ? (<>
                <h4>Nombre de lignes:</h4>
                <select id='nbrLignes'>
                  {Array.from({ length: 100 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
                <br />
                <h4>Nombre de postes:</h4>
                <select id='nbrLignes'>
                  {Array.from({ length: 100 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
                <br />
                <h4>PI:</h4>
                <input id='pi'></input>
                <br />
                <h4>PS:</h4>
                <input id='ps'></input>
                <br />
                <h4>Sans Fil:</h4>
                <input id='S-fil'></input>
                <br />
                <h4>Mobiles:</h4>
                <input id='Mobiles'></input>
                <br />
                <h4>MachAffr:</h4>
                <input id='MachAffr'></input>
                <br />
                <h4>Copieur:</h4>
                <select id='Copieur'>
                  <option value='non'>non</option>
                  <option value='NB'>NB</option>
                  <option value='C'>C</option>
                </select>
                <br />
                <h4>Copie:</h4>
                <select id='Copie'>
                  <option value="none">none</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  <option value="300">300</option>
                  <option value="350">350</option>
                  <option value="400">400</option>
                  <option value="450">450</option>
                  <option value="500">500</option>
                  <option value="550">550</option>
                  <option value="600">600</option>
                  <option value="1000">1000</option>
                  <option value="+">+</option>
                </select>
                <br />
              </>) : (<></>)}
              <a onClick={systemModal}>
                <div className='classic'>
                  <h3>Systeme d'information</h3>
                </div>
              </a>
              {SystemInfo ? (<>
                <div className='mid'>
                  <div className='just_row'>
                    <h4>Me Vo:</h4>
                    <input type="checkbox" id='Me vo' className='check' />
                  </div>

                  <br />

                  <div className='just_row'>
                    <h4>Mus.Perso:</h4>
                    <input type="checkbox" id='MusPerso' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Pré décroché:</h4>
                    <input type="checkbox" id='PredecAS' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Tuner:</h4>
                    <input type="checkbox" id='Tuner' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Répondeur:</h4>
                    <input type="checkbox" id='Répondeur' className='check' />
                  </div>
                  <br />
                </div>
              </>) : (<></>)}
            </div>
          </div>
        </ div>
      </>) : (<></>)}
      {MenuP ? (<>
        <div style={positionningPmenu}>
          <div className='flex3'>
            <div>
              <h1>
                Information Supplémentaire
              </h1>
            </div>
            <br />
            <div className='mid'>
              <div className='just_row'>
                <h4>Ascenseur:</h4>
                <input type="checkbox" id='Ascenseur' className='check' />
              </div>

              <br />

              <div className='just_row'>
                <h4>T.P.E:</h4>
                <input type="checkbox" id='TPE' className='check' />
              </div>
              <br />
              <div className='just_row'>

                <h4>Sonnerie generale:</h4>
                <input type="checkbox" id='sonnGen' className='check' />
              </div>
              <br />
              <div className='just_row'>
                <h4>ALARME:</h4>
                <input type="checkbox" id='Alarme' className='check' />
              </div>
              <br />
              <a onClick={rappel}>
                <div className='classic'>
                  <h3>rappel</h3>
                </div>
              </a>
              {Rappel ? (<>
                <div className='mid'>
                  <br />
                  <div className='just_row'>
                    <h4>Renvoi</h4>
                    <input type="checkbox" id='Renvoi' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Musique d'attente</h4>
                    <input type="checkbox" id='MusicAtt' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Pré-décrocher</h4>
                    <input type="checkbox" id='Predec' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Messagerie vocal</h4>
                    <input type="checkbox" id='MessriVoc' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Standart Auto</h4>
                    <input type="checkbox" id='StndrtAuto' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Casques</h4>
                    <input type="checkbox" id='Casques' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Aboutement</h4>
                    <input type="checkbox" id='Aboutement' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Presentation du n°</h4>
                    <input type="checkbox" id='PresNumber' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Rationnalisation</h4>
                    <input type="checkbox" id='Rationnalisation' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>S.D.A</h4>
                    <input type="checkbox" id='SDA' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>DISA</h4>
                    <input type="checkbox" id='DISA' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Annuaire</h4>
                    <input type="checkbox" id='Annuaire' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Liste des appelants</h4>
                    <input type="checkbox" id='Liste des appelants' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Main libres</h4>
                    <input type="checkbox" id='Main libres' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Gigaset/cordless/Lp</h4>
                    <input type="checkbox" id='GCL' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Buffer</h4>
                    <input type="checkbox" id='Buffer' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Opérateur</h4>
                    <input type="checkbox" id='Operateur' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Discrimination</h4>
                    <input type="checkbox" id='Discrimination' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Conférence</h4>
                    <input type="checkbox" id='Conference' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Démarche FT</h4>
                    <input type="checkbox" id='DemarcheFT' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>CTI mess uni</h4>
                    <input type="checkbox" id='CTIMessUni' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Enreg</h4>
                    <input type="checkbox" id='Enreg' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Supervision mono multi</h4>
                    <input type="checkbox" id='SupervisionMM' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Hot line</h4>
                    <input type="checkbox" id='Hot line' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Formule 3 + 2</h4>
                    <input type="checkbox" id='Formule' className='check' />
                  </div>
                  <br />
                  <div className='just_row'>
                    <h4>Contrat de Maintenance</h4>
                    <input type="checkbox" id='Contrat_Maintenance' className='check' />
                  </div>
                  <br />
                </div>
              </>) : (<></>)}

            </div>
          </div>
        </div>
      </>) : (<></>)}
    </div>
    <div className='widget ServiceCtnr'>
      <div className='content'>
        <div className='text'>
          <h1>Nos Services</h1>
          <br />
          <br />
          <button className='Button' onClick={handleClickTelephone}>{telephone ? 'Téléphonie on' : 'Téléphonie off'}</button>
          <button className='Button' onClick={handleClickPartage}>{partage ? 'Partage on' : 'Partage off'}</button>
          <button className='Button' onClick={handleClickSecurity}>{security ? 'Securité on' : 'Securité off'}</button>
          <div className='flex'>
            {renderImage()}
          </div>
          <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br />
        </div>
      </div>
    </div>
    <Footer />

  </>)
}
export default Service;