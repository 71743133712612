import React, { useState, useRef } from "react";
import logoC from "../Components/img/logoTCS.png";
import bluephone from './img/trnsbluephone.png';

import './styles/Header.css';

function Acceuil() {
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [isHovered, setIsHovered] = useState(false);
  const [isSmall, setIsSmall] = useState(windowWidth.current < 640);

  const containerStyles = {
    display: "flex",
    alignItems: "center", // Adjust as needed
    justifyContent: "space-between", // Adjust as needed
  };

  const telimgStyles = {
    position: "relative",
    marginTop: "11px",
    height: "45%",
    zIndex: 5,
    borderRadius: "50%",
    transition: "border-radius 0.3s"
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className='header'>
        <div className='mainContainer' style={containerStyles}>
          <div className="spc"></div>
          <a href="/" className='ltcs'><img src={logoC} className='img' alt="TCS Logo"></img></a>
          <div className="middle">
            <a href="/Service"><button className="menu">Nos services</button></a>
            <a href="/Pourquoi"><button className="menu">Pourquoi T.C.S ?</button></a>
            <a href="/Produits"><button className="menu">Nos produits</button></a>
              <a href="/Contact"><button className={isHovered ? 'menuDevis hovered' : 'menuDevis'} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Obtenir un devis</button></a>
              </div>
              <div className="headerrow">
                <a href="/Contact">
                <img className={isHovered ? "telimg hovered" : "telimg"} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={bluephone} />
                </a>
              </div>
        </div>
      </div>
    </>
  );
}

export default Acceuil;
