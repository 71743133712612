import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "../context/AuthProvider"
import axios from '../api/axios';
import Header from './Header'
import './styles/Login.css'
import Book from './img/Book.png'
import Sign from './img/Sign.png'
const LOGIN_URL = "/api/auth/signin"

const Login = () => {
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState(true);
    const { setAuth } = useContext(AuthContext)

    useEffect(() => {
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd, email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            const accessToken = response.data.accessToken;
            setAuth({ user, pwd, email, accessToken })
            setUser('');
            setPwd('');
            setEmail('');
            setSuccess(true);
        } catch (e) {
            if (!e?.response) {
                setErrorMsg('No Server Response');
            }
            else if (e.response?.status === 400) {
                setErrorMsg('Missing Username or Password');
            }
            else if (e.response?.status === 401) {
                setErrorMsg('Unauthorized');
            }
            else {
                setErrorMsg('Login failed');
            }
            errRef.current.focus();
        }


    }


    return (
        <>
            <Header />
            <div className='Card'>
                {success ? (
                    <section>
                        <div>
                        <h1>Vous Ete Connectez !</h1>
                        <p>
                        <h1>Choisissez votre menu :</h1>
                        <br/>
                        <div className='row'>
                        <div className='blue'><a href='/book'><img  className='imgContain' src={Book}></img></a></div>
                        <div className='red'><a href='/sign'><img className='imgContain' src={Sign}></img></a></div>
                        </div>
                            <a href="/">Go to home</a>
                        </p>
                        </div>
                    </section>
                ) : (
                    <section>
                        <p ref={errRef} className={errorMsg ? "errormsg" : "offscreen"} aria-live="assertive">{errorMsg}</p>
                        <h1>Sign in</h1>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <input
                                className='label LoginLabel'
                                placeholder='username'
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required />

                            <input
                                className='label LoginLabel'
                                placeholder='password'
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required />

                            <input
                                className='label LoginLabel'
                                placeholder='email'
                                type="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required />
                            <button className='Signin'>Sign in</button>
                        </form>
                        <br />
                    </section>
                )}
            </div>
        </>

    )
}

export default Login
