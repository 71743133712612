import App from '../App';
import { useFetcher } from 'react-router-dom';
import './styles/BookServices.css'
import { useState, useEffect } from 'react'
import Footer from './Footer';
import HomeBtn from './HomeBtn';
import Header from './Header'

import Ligneimg from './img/Circle/lignefibre.png';
import Voip from './img/Circle/Voip.png';
import Casque from './img/Circle/Casque.png';
import lignefibre from './img/Circle/lignefibre.png';
import Musiquedatt from './img/Circle/Musiquedatt.png';
import Portable from './img/Circle/portable.png';
import Support from './img/Circle/SupportEcoute.png';
import Fibre from './img/Circle/Fibre.png';
import vdsl from './img/Circle/vdsl.png';
import adsl from './img/Circle/adsl.png';
import Mobile from './img/Circle/Mobile.png';
import Security from './img/Security.png';
import Voip_pabx from './img/VOIP_PABX.png';
import unify from './img/book-services/unifierAbonement.png';

import './HomeBtn'
import './styles/Service.css'
function Service() {
  return (<>
    <HomeBtn />
    <div className='widget ServiceCtnr'>
      <div className='content'>
        <div className='text'>
          <h1>Nos services</h1>
          <br />
          <br />
          <h1><u>Connecter ma téléphonie :</u></h1>
          <br />
          <img className='unify' src={Voip_pabx}/>
          <br />
          <br />
          <br />
          <h1><u>Partager mes données</u></h1>
          <br/>
          <h3>Intranet</h3>
          <h3>vidéosurveillance</h3>
          <h3>Serveur commun/dédié</h3>
          <br />
          <br />
          <h1><u>Sécuriser mon activité :</u></h1>
          <br />
          <img className='unify' src={Security}/>
          <br />
          <br />
          <br />
          <h1><u>Sécuriser mon réseau</u></h1>
          <br />
          <h3>Pare-feu</h3>
          <h3>VPN</h3>
          <h3>Journalisation/logs</h3>
          <h3>Sensibilisation salariés</h3>
          <br />
          <br />
          <h1><u>Unifier mes abonnements</u></h1>
          <br />
          <br />
          <img className='unify' src={unify}></img>  
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
    <Footer />
  </>)
}
export default Service;