import logoC from "../Components/img/logoTCS.png";
import React, { useState } from "react";
import Header from  './Header';
import logo from "./img/newtcsbcg.png";
import imgbtn from './img/homebtnimg.png'
import './styles/Menu.css'
function Menu() {
    return(
        <>
     <img className="homebutton" src={imgbtn}/> 
        </>
    )
}
export default Menu;